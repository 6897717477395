

































































































































































































































































































































































































































































































.form-textarea {
  .el-textarea {
    .el-textarea__inner {
      min-height: 13rem !important;
      resize: none;
    }
  }
}
.activeSetbtn {
  display: flex;
  justify-content: center;
  padding-bottom: 0.75rem;
}
.integral {
  display: flex;
  margin-bottom: 0.75rem;
  align-items: center;
  .integralTitle {
    min-width: 4rem;
  }
  > div:nth-child(3) {
    padding-left: 1.5rem;
  }
}
// .el-form-item {
//   display: flex;
//   align-items: center;
//   .el-form-item__content {
//     width: 100%;
//     margin-left: 0 !important;
//   }
// }
.form-control {
  align-items: flex-start;
}
.form-integral {
  align-items: flex-start;
  .form-integralbox {
    padding-bottom: 0.75rem;
  }
}
.form-itemDate {
  width: 50%;
}
